/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-dashboard-pro-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function RWSFooter(props) {
    const classes = useStyles();
    const { fluid, white } = props;
    var container = cx({
        [classes.container]: fluid,
        [classes.containerFluid]: !fluid,
        [classes.whiteColor]: white
    });
    var anchor =
        classes.a +
        cx({
            [" " + classes.whiteColor]: white
        });
    return (
        <footer className={classes.footer}>
            <div className={container}>
                <p className={classes.left}>
                    {`Made with ❤️ By `}
                    <a
                        href="https://www.assimilated.com.sg/"
                        className={anchor}
                        target="_blank"
                    >
                        {"Assimilated Techonologies"}
                    </a>
                </p>
            </div>
        </footer>
    );
}

RWSFooter.propTypes = {
    fluid: PropTypes.bool,
    white: PropTypes.bool
};
