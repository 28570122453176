import { getUrl } from "utils/httpClient"
import axios from 'axios'

const GetUserListUrl = "/user/getusers"
const GetUserListTotalCountUrl = "/user/getuserstotalcount";
const RegisterUserUrl = "/user/register"
const GetUserById = "/user/getuserbyid"
const UpdatedUserUrl = "/user/updateuser"
const DeleteUserUrl = "/user/deleteuser";

async function getUserList(index, size) {

    const instance = axios.create({
        //More setting can be found here https://github.com/axios/axios
        baseURL: getUrl().BASEURL + getUrl().URLPREFIX,
        // timeout: 3000,
        headers: {
            "Content-type": "application/json",
            "Authorization" : `Bearer ${window.localStorage.getItem("user") ? JSON.parse(window.localStorage.getItem("user")).token : ""}`
        }
    })

    const response = await instance.get(GetUserListUrl, {
        params: {
            index: index,
            size: size
        }
    })
    const {
        data
    } = response
    return data
}

async function getUserListTotalCount() {

    const instance = axios.create({
        //More setting can be found here https://github.com/axios/axios
        baseURL: getUrl().BASEURL + getUrl().URLPREFIX,
        // timeout: 3000,
        headers: {
            "Content-type": "application/json",
            "Authorization" : `Bearer ${window.localStorage.getItem("user") ? JSON.parse(window.localStorage.getItem("user")).token : ""}`
        }
    })

    const response = await instance.get(GetUserListTotalCountUrl)
    const {
        data
    } = response
    return data
}

async function getUserById(userId) {

    const instance = axios.create({
        //More setting can be found here https://github.com/axios/axios
        baseURL: getUrl().BASEURL + getUrl().URLPREFIX,
        // timeout: 3000,
        headers: {
            "Content-type": "application/json",
            "Authorization" : `Bearer ${window.localStorage.getItem("user") ? JSON.parse(window.localStorage.getItem("user")).token : ""}`
        }
    })

    const response = await instance.get(GetUserById, {
        params: {
            id: userId
        }
    })
    const {
        data
    } = response
    return data
}

async function registerUser(username, password) {
    const instance = axios.create({
        //More setting can be found here https://github.com/axios/axios
        baseURL: getUrl().BASEURL + getUrl().URLPREFIX,
        // timeout: 3000,
        headers: {
            "Content-type": "application/json",
            "Authorization" : `Bearer ${window.localStorage.getItem("user") ? JSON.parse(window.localStorage.getItem("user")).token : ""}`
        }
    })

    const response = await instance.post(RegisterUserUrl, {
        UserName: username,
        Password: password,
        RoleId: 1,
        // StaffID: staffId,
        // Department: department
    })
    const {
        data
    } = response
    return data
}

async function updateUser(id, username, password) {
    const instance = axios.create({
        baseURL: getUrl().BASEURL + getUrl().URLPREFIX,
        // timeout: 3000,
        headers: {
            "Content-type": "application/json",
            "Authorization" : `Bearer ${window.localStorage.getItem("user") ? JSON.parse(window.localStorage.getItem("user")).token : ""}`
        }
    })

    const response = await instance.post(UpdatedUserUrl, {
        Id: id,
        UserName: username,
        Password: password,
        // StaffID: staffId,
        RoleId: 1,
        // Department: department
    })
    const {
        data
    } = response
    return data
}

async function deleteUser(id) {
    const instance = axios.create({
        baseURL: getUrl().BASEURL + getUrl().URLPREFIX,
        // timeout: 3000,
        headers: {
            "Content-type": "application/json",
            "Authorization" : `Bearer ${window.localStorage.getItem("user") ? JSON.parse(window.localStorage.getItem("user")).token : ""}`
        }
    })

    const response = await instance.post(DeleteUserUrl, {
        Id: id,
    })
    const {
        data
    } = response
    return data
}


export default {
    getUserList,
    getUserListTotalCount,
    getUserById,
    registerUser,
    updateUser,
    deleteUser
}