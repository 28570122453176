import moment from 'moment'

function convertDateToTicks(date) {
    return (date.getTime() * 10000) + 621355968000000000
}

function convertDateTimeToDate(date) {
    return moment(date).format('DD-MM-YYYY')
}

function convertDateTimeToTime(date) {
    return moment(date).format('hh:mm a')
}

function convertDateTimeToString(date) {
    return moment(date).format('DD-MM-YYYY hh:mm:ss a')
}

function convertDateTimeToString24(date) {
    return moment(date).format('DD-MM-YYYY hh:mm:ss')
}

function convertDateToString(date) {
    return moment(date).format('DD/MM/YYYY')
}

function subtractDay(date, day) {
    return moment(date).subtract(day, 'day').toDate()
}

function addDay(date, day) {
    return moment(date).add(day, 'day').toDate()
}

function isChildAge(DOB) {
    return moment(DOB) > moment(new Date()).subtract(15, 'year')
}

function dayDifference(fromDate, toDate) {
    return moment(fromDate).diff(moment(toDate), 'day')
}

export {
    convertDateToTicks,
    convertDateTimeToDate,
    convertDateTimeToTime,
    convertDateTimeToString,
    convertDateTimeToString24,
    convertDateToString,
    subtractDay,
    addDay,
    isChildAge,
    dayDifference
}