import React, { useState, useContext, createContext } from "react";
//Services
import AccountService from "services/AccountService"
import UserService from "services/UserService"

import jwt from 'jwt-decode'

const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [route, setRoute] = useState(JSON.parse(localStorage.getItem("route")) == null ? [] : JSON.parse(localStorage.getItem("route")));
  const [menu, setMenu] = useState(JSON.parse(localStorage.getItem("menu")) == null ? [] : JSON.parse(localStorage.getItem("menu")));
  const [accessControlList, setAccessControlList] = useState(JSON.parse(localStorage.getItem("function")) == null ? [] : JSON.parse(localStorage.getItem("function")));

  // ... to save the user to state.
  const signin = (email, password) => {

    return AccountService.userLogin(email, password)
      .then(response => {

        const user = {
          name: email,
          token: response.data.token
        }

        updateUser(user)

        return user
      })
  };

  const signout = () => {
    setUser(null)

    window.localStorage.removeItem("user")
    window.localStorage.removeItem("route")
    window.localStorage.removeItem("menu")
    window.localStorage.removeItem("function")
  };

  const updateUser = (user) => {
    window.localStorage.setItem("user", JSON.stringify(user))
    setUser(user)
  }

  const getUserPermission = (user) => {
    const { UserId } = jwt(user.token)

    console.log(UserId)

    return UserService.getUserById(UserId)
      .then(response => {

        console.log(response)

        // const menusList = response.Menus
        const menusList = response.Role.Menus

        // // console.log({...user, role: response.Name})
        updateUser({...user, role: response.Role.Name})
        
        updateMenuAndRoute(menusList)
        // updateAccessControlList(response.Functions)

        return true
      });
  };

  const updateMenuAndRoute = (menusList) => {

    const moduleList = menusList.filter(menu => menu.Type === "Module")
    const menuList = menusList.filter(menu => menu.Type === "Menu")
    var menuNavListing = []

    moduleList.forEach((module) => {
      var header = {
        title: module.Name,
        dropdownItem: []
      }

      const list = menuList.filter(menu => menu.ParentId === module.Id)

      list.forEach((menu) => {

        header.dropdownItem.push({
          title: menu.Name,
          uri: menu.Path
        })
      })

      menuNavListing.push(header)
    })

    window.localStorage.setItem("menu", JSON.stringify(menuNavListing))
    setMenu(menuNavListing)

    window.localStorage.setItem("route", JSON.stringify(menuList))
    setRoute(menuList)
  };

  const updateAccessControlList = (functionList) => {
    const functions = functionList.map(x => x.Code)
    window.localStorage.setItem("function", JSON.stringify(functions))

    setAccessControlList(functions)
  };

  // Return the user object and auth methods
  return {
    user,
    menu,
    route,
    accessControlList,
    signin,
    signout,
    getUserPermission,
    updateMenuAndRoute,
    updateAccessControlList,
  };
}