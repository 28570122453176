import React, { useState } from 'react'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from 'components/CustomButtons/Button'
import Typography from '@material-ui/core/Typography'

import { useAuth } from 'utils/AuthUser'
import { useHistory } from "react-router-dom"

export default function Home() {
    const auth = useAuth();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false)
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    function loginUser() {
        setIsLoading(true)

        auth.signin(username, password)
            .then(user => {
                setIsLoading(false)

                if (user.token != null) {
                    auth.getUserPermission(user).then(result => {

                        if (result) {
                            history.push('/admin/dashboard')
                        }
                    }).catch(error => {
                        alert(error)
                    })
                } else {
                    alert("Invalid login.")
                }
            }).catch(error => {
                setIsLoading(false)
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    switch (error.response.status) {
                        case 401:
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                            break;

                        default:
                            break;
                    }

                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                alert(error)
            })
    }

    return (
        <>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ minHeight: '100vh' }}
            >
                <Grid item xs={12}>
                    <Typography align='center' variant='h3'>eGate Management System</Typography>
                </Grid>

                <Grid item xs={12}>
                    <form autoComplete="off">
                        <TextField
                            required
                            fullWidth
                            margin="normal"
                            label="Username"
                            variant="outlined"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            onKeyPress={(event) => {
                                if (event.key === 'Enter')
                                    loginUser()
                            }} />
                        <TextField
                            required
                            fullWidth
                            margin="normal"
                            label="Password"
                            variant="outlined"
                            value={password}
                            type="password"
                            onChange={(e) => setPassword(e.target.value)}
                            onKeyPress={(event) => {
                                if (event.key === 'Enter')
                                    loginUser()
                            }} />
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="rwsSecondary"
                            loading={isLoading}
                            onClick={loginUser}
                        >
                            Sign In
                        </Button>
                    </form>
                </Grid>
            </Grid>
        </>
    )
}