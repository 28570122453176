import { publicApi } from "utils/httpClient"

const LoginUrl = "/Account/Login"

async function userLogin(username, password) {
    return publicApi.post(LoginUrl, {
        username: username,
        password: password
    })
}

export default {
    userLogin
}