import axios from 'axios'

const debug = {
    BASEURL: 'http://localhost:5000',
    URLPREFIX: '/api'
};

const dev = {
    BASEURL: 'https://dev.assimilated.com.sg', //'http://spcp.assimilated.com.sg:92',
    URLPREFIX: '/egate/api'
};

const uat = {
    BASEURL: 'https://utstays.rwsentosa.com',
    URLPREFIX: '/egate/api'
};

const prod = {
    BASEURL: 'https://stays.rwsentosa.com:30202',
    URLPREFIX: '/api'
};

function getUrl() {
    return process.env.REACT_APP_MODE === 'prod' ? prod :
        process.env.REACT_APP_MODE === 'uat' ? uat :
        process.env.REACT_APP_MODE === 'dev' ? dev :
        debug
};

const publicApi = axios.create({
    //More setting can be found here https://github.com/axios/axios
    baseURL: getUrl().BASEURL + getUrl().URLPREFIX,
    timeout: 3000,
    headers: {
        "Content-type": "application/json",
    },
    auth: {
        username: 'assimilated',
        password: 'P@s5uu0rD'
    },
});

const privateApi = axios.create({
    //More setting can be found here https://github.com/axios/axios
    baseURL: getUrl().BASEURL + getUrl().URLPREFIX,
    timeout: 3000,
    headers: {
        "Content-type": "application/json",
        "Authorization" : `Bearer ${window.localStorage.getItem("user") ? JSON.parse(window.localStorage.getItem("user")).token : ""}`
    }
});

export {
    publicApi,
    privateApi,
    getUrl
}