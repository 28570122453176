import React, { useState, useEffect } from "react";

import { DataGrid } from "@material-ui/data-grid";
import Typography from '@material-ui/core/Typography';
import CustomNoRowsOverlay from "components/DataGrid/CustomNoRowsOverlay";
import CustomLoadingOverlay from "components/DataGrid/CustomLoadingOverlay";
import CustomPagination from "components/DataGrid/CustomPagination";
// import AccessControlButton from "components/AccessControlButton/AccessControlButton";
import { Button, Grid, TextField } from "@material-ui/core";
import GridItem from "components/Grid/GridItem";
// import MenuItem from '@material-ui/core/MenuItem';
import RWSAdminNavbarLink from "components/Navbars/RWSAdminNavbarLinks.js";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import RWSFooter from "components/Footer/RWSFooter";

//Services
import UserService from "services/UserService"
// import RoleService from "services/RoleService"

//Helper
import { handleHttpResponseError } from "utils/HandleError"
import { convertDateTimeToString24 } from "utils/dateHelper"

import { useAuth } from 'utils/AuthUser'

export default function UserManagement() {
    const auth = useAuth();
    const [page, setPage] = useState(1);
    const [size] = useState(10);
    const [loadUserList, setLoadUserList] = useState(true)
    const [userList, setUserList] = useState([])
    // const [loadRoleList, setLoadRoleList] = useState(true)
    const [selectedRoleType, setSelectedRoleType] = useState("")
    // const [roleList, setRoleList] = useState([])
    const [addUser, setAddUser] = useState(null)
    const [editUser, setEditUser] = useState(null)
    const [removeUser, setRemoveUser] = useState(null)
    const [deleteConfirmation, setDeleteConfirmation] = useState(null);
    const [userListTotalCount, setUserListTotalCount] = useState(0);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    // Callback
    // const getRoleId = useCallback((name) => {
    //     return roleList.find(role => role.Name === name) ? roleList.find(role => role.Name === name).Id : 0
    // }, [roleList])

    // Component will did mount 
    useEffect(() => {

        async function getUserList() {
            try {
                if (loadUserList) {
                    const userList = await UserService.getUserList((size * page) - size)
                    setUserList(userList)
                    setLoadUserList(false)
                }
            } catch (error) {
                handleHttpResponseError(auth, error)
            }
        }

        getUserList()
    }, [page, size, loadUserList, auth])

    useEffect(() => {

        async function getUserListTotalCount() {
            try {
                const data = await UserService.getUserListTotalCount()
                setUserListTotalCount(data.TotalCount);
            } catch (error) {
                handleHttpResponseError(auth, error)
            }
        }

        getUserListTotalCount()
    }, [loadUserList, auth])

    // useEffect(() => {

    //     async function getRoleList() {
    //         try {
    //             if (loadRoleList) {
    //                 const roleList = await RoleService.getRoles()
    //                 setRoleList(roleList)
    //                 setLoadRoleList(false)
    //             }
    //         } catch (error) {
    //             handleHttpResponseError(auth, error)
    //         }
    //     }
    //     getRoleList()

    // }, [loadRoleList, auth])

    //Setting up the each column header item
    const usernameHeader = {
        field: "UserName",
        headerName: "Username",
        flex: 1,
    };

    // const staffIdHeader = {
    //     field: "StaffId",
    //     headerName: "Staff Id",
    //     flex: 1,
    // };

    // const departmentHeader = {
    //     field: "Department",
    //     headerName: "Department",
    //     flex: 2,
    // };

    const actionsHeader = {
        field: "Id",
        headerName: "Actions",
        width: 180,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => (
            <strong>
                <Button id="edit_user"
                    variant="contained"
                    color="primary"
                    size="small"
                    value={params.value}
                    onClick={handleOpenEditDialog}
                >
                    Edit
                </Button>
                <Button id="delete_user"
                    variant="contained"
                    color="primary"
                    size="small"
                    value={params.value}
                    style={{ marginLeft: 16 }}
                    onClick={handleOpenDeleteDialog}
                >
                    Delete
                </Button>

            </strong>
        ),
    };

    // const roleNameHeader = {
    //     field: "Role",
    //     headerName: "Role",
    //     flex: 1,
    //     valueFormatter: ({ value }) => value.Name,
    // };

    const lastLoginHeader = {
        field: "LastLoginDate",
        headerName: "Last Login Date",
        flex: 1,
        valueFormatter: ({ value }) => value != null ? convertDateTimeToString24(value) : "",
    };

    //Header column for guest
    const headerColumn = [
        usernameHeader,
        // staffIdHeader,
        // departmentHeader,
        // roleNameHeader,
        // lastLoginHeader,
        actionsHeader
    ];

    const handlePageChange = (params) => {
        setPage(params.page)
        setLoadUserList(true)
    };

    const handleOpenCreateDialog = () => {
        setOpenEditDialog(true);
    };

    const handleOpenEditDialog = event => {
        var updateUser = userList.find(user => user.Id === parseInt(event.currentTarget.value))
        setEditUser(updateUser)
        setSelectedRoleType(updateUser.Role.Name)
        setOpenEditDialog(true);
    };

    const handleEditOrAdd = async () => {
        if (editUser == null) {
            if (addUser == null || addUser.UserName == null || addUser.UserName === "" || addUser.Password == null || addUser.Password == "") {
                alert("Please fill up the details below to add a new user.")
            } else {
                //Add
                await UserService.registerUser(addUser.UserName, addUser.Password)
                setAddUser(null)
                setLoadUserList(true)
                setOpenEditDialog(false);
            }
        } else {
            //Update
            await UserService.updateUser(editUser.Id, editUser.UserName, editUser.Password)
            setEditUser(null)
            setLoadUserList(true)
            setOpenEditDialog(false);
        }
    };

    const handleOpenDeleteDialog = event => {
        var removeUser = userList.find(user => user.Id === parseInt(event.currentTarget.value))
        setRemoveUser(removeUser)
        setSelectedRoleType(removeUser.Role.Name)
        setOpenDeleteDialog(true);
    };

    const handleDelete = async () => {
        if (deleteConfirmation !== removeUser.UserName) {
            alert("Please key in the username to confirm deletion.")
        } else {
            await UserService.deleteUser(removeUser.Id)
            setRemoveUser(null)
            setLoadUserList(true)
            setDeleteConfirmation(null)
            setOpenDeleteDialog(false);
        }
    };

    const handleEditClose = () => {
        setEditUser(null)
        setRemoveUser(null)
        setOpenEditDialog(false)
        setOpenDeleteDialog(false)
    };

    return (
        <>
            <RWSAdminNavbarLink />
            <div>
                <Grid container direction="row" justify="space-between" alignItems="center" style={{ padding: "8px" }}>
                    <GridItem>
                        <Typography
                            style={{ margin: '10px' }}
                            variant='h6'>User management</Typography>
                    </GridItem>
                    <GridItem>
                        <Button id="add_user" variant="contained" color="primary" onClick={handleOpenCreateDialog}>
                            Add user
                        </Button>

                    </GridItem>
                </Grid>

                {/* Edit Dialog */}
                <Dialog open={openEditDialog} onClose={handleEditClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{editUser != null ? "Edit User" : "Add User"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {editUser != null ? 'To update user details, change the detail below and save it.' : 'Fill up the details below to add a new user.'}
                        </DialogContentText>
                        <form noValidate autoComplete="off">
                            <div>
                                <TextField
                                    margin="dense"
                                    id="name"
                                    label="Username"
                                    type="text"
                                    value={editUser != null ? editUser.UserName : null}
                                    onChange={(e) => { editUser != null ? setEditUser({ ...editUser, UserName: e.currentTarget.value }) : setAddUser({ ...addUser, UserName: e.currentTarget.value }) }}
                                    fullWidth
                                />
                                <TextField
                                    margin="dense"
                                    id="password"
                                    label={editUser != null ? "New Password" : "Password"}
                                    type="password"
                                    onChange={(e) => { editUser != null ? setEditUser({ ...editUser, Password: e.currentTarget.value }) : setAddUser({ ...addUser, Password: e.currentTarget.value }) }}
                                    fullWidth
                                />
                                {/* <TextField
                                    margin="dense"
                                    id="department"
                                    label="Department"
                                    type="text"
                                    defaultValue={editUser != null ? editUser.Department : null}
                                    onChange={(e) => { editUser != null ? setEditUser({ ...editUser, Department: e.currentTarget.value }) : setAddUser({ ...addUser, Department: e.currentTarget.value }) }}
                                    fullWidth
                                /> */}
                                {/* <TextField
                                    id="roleList"
                                    select
                                    margin="dense"
                                    fullWidth
                                    label="Role"
                                    value={selectedRoleType}
                                    onChange={(e) => setSelectedRoleType(e.target.value)}
                                    helperText="Please select a Role"
                                >
                                    {roleList.map((role) => (
                                        <MenuItem key={role.Id} value={role.Name}>
                                            {role.Name}
                                        </MenuItem>
                                    ))}
                                </TextField> */}
                            </div>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleEditClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleEditOrAdd} color="primary">
                            {editUser != null ? `Save & Update` : `Add user`}
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* delete Dialog */}
                {removeUser != null ?
                    <Dialog open={openDeleteDialog} onClose={handleEditClose} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Delete User</DialogTitle>
                        <DialogContent>
                            <TextField
                                margin="normal"
                                id="name"
                                label="Username"
                                type="text"
                                value={removeUser.UserName != null ? removeUser.UserName : ""}
                                fullWidth
                                disabled
                            />
                            {/* <TextField
                                margin="normal"
                                id="staffId"
                                label="Staff Id"
                                type="text"
                                defaultValue={removeUser.StaffId != null ? removeUser.StaffId : ""}
                                fullWidth
                                disabled
                            /> */}
                            {/* <TextField
                                margin="normal"
                                id="department"
                                label="Department"
                                type="text"
                                defaultValue={removeUser.Department != null ? removeUser.Department : ""}
                                fullWidth
                                disabled
                            /> */}
                            {/* <TextField
                                disabled
                                id="roleList"
                                select
                                margin="dense"
                                fullWidth
                                label="Role"
                                value={selectedRoleType}
                                onChange={(e) => setSelectedRoleType(e.target.value)}
                                helperText="Please select a Role"
                            >
                                {roleList.map((role) => (
                                    <MenuItem key={role.Id} value={role.Name}>
                                        {role.Name}
                                    </MenuItem>
                                ))}
                            </TextField> */}
                            <DialogContentText style={{ marginTop: '18px' }}>
                                {`Are you sure you want to delete ${removeUser.UserName}?`}
                            </DialogContentText>
                            <DialogContentText>
                                Please type the following to confirm:
                            </DialogContentText>
                            <DialogContentText>
                                <i><b>{removeUser.UserName}</b></i>
                            </DialogContentText>
                            <form noValidate autoComplete="off">
                                <div>
                                    <TextField
                                        margin="dense"
                                        variant="outlined"
                                        id="name"
                                        type="text"
                                        value={deleteConfirmation}
                                        onChange={(e) => setDeleteConfirmation(e.currentTarget.value)}
                                        fullWidth
                                    />
                                </div>
                            </form>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleEditClose} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={handleDelete} color="primary">
                                {`Delete`}
                            </Button>
                        </DialogActions>
                    </Dialog>
                    : null}

                {/* Data Grid */}
                <div style={{ height: 520, padding: '18px' }}>
                    <div style={{ display: "flex", height: "100%" }}>
                        <div style={{ flexGrow: 1 }}>
                            <DataGrid
                                pageSize={size}
                                density='compact'
                                disableMultipleSelection
                                components={{
                                    LoadingOverlay: CustomLoadingOverlay,
                                    NoRowsOverlay: CustomNoRowsOverlay,
                                    Pagination: CustomPagination,
                                }}
                                rows={userList}
                                columns={headerColumn}
                                page={page}
                                disableColumnFilter
                                getRowId={(row) => row.Id}
                                paginationMode="server"
                                rowCount={userListTotalCount}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <RWSFooter />
        </>
    );
}