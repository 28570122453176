/*!

=========================================================
* Material Dashboard PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

import './fonts/Poppins-Black.otf';
import './fonts/Poppins-Bold.otf';
import './fonts/Poppins-Italic.otf';
import './fonts/Poppins-Medium.otf';
import './fonts/Poppins-Regular.otf';
import './fonts/Poppins-SemiBold.otf';

import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";
//For react bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';

//
import { ProvideAuth } from "utils/AuthUser";
import { ProvideSignalR } from "utils/SignalR";
import Routes from "routes.js"

const hist = createBrowserHistory();

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#b6a281',
    },
  },
  overrides: {
    MuiSnackbarContent: {
      message: {
        fontSize: 20,
        lineHeight: 1.1,
      },
    },
    MuiSnackbar: {
      anchorOriginTopCenter: {
        top: "20px",
      },
    },
    MuiStepConnector: {
      line: {
        borderColor: 'transparent'
      }
    },
    MuiFormControlLabel: {
      label: {
        fontWeight: 'lighter',
        color: 'black',
        fontSize: 14
      }
    },
    MuiStepLabel: {
      iconContainer: {
        paddingRight: '0px !important'
      }
    },
    MuiStepIcon: {
      root: {
        width: 7,
        height: 7,
        '&$completed': {
          color: '#b6a281',
          height: 7,
          width: 7
        },
        '&$active': {
          color: '#b6a281',
          height: 10,
          width: 10
        }
      },
      active: {},
      completed: {},
      text: {
        fill: 'transparent',
      }
    }
  },
});

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <ProvideAuth>
        <ProvideSignalR>
          <Router history={hist}>
            <Routes />
          </Router>
        </ProvideSignalR>
    </ProvideAuth>
  </MuiThemeProvider>,
  document.getElementById("root")
);