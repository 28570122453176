//more info can be found here https://www.npmjs.com/package/react-pdf

import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import ControlPanel from './ControlPanel';

import Grid from '@material-ui/core/Grid';
import GridItem from 'components/Grid/GridItem';

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js'; //manually import worker

const PDFReader = ({ filename = "file.pdf", file, fileInBase64 }) => {
  const [scale, setScale] = useState(1.0);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const fileSource = file ? file : `data:application/pdf;base64,${fileInBase64}`

  return (
    <>
      {/* <Loader isLoading={isLoading} />
      <section
        id="pdf-section"
        className="d-flex flex-column align-items-center w-100"
      >
        <ControlPanel
          scale={scale}
          setScale={setScale}
          numPages={numPages}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          file={file}
        />
        <Document
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} scale={scale} />
        </Document>
      </section> */}
      <Grid container direction='column' alignItems='center' justify='center' alignContent='center'>
        <GridItem>
          <ControlPanel
            scale={scale}
            setScale={setScale}
            numPages={numPages}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            file={fileSource}
            filename={filename}
          />
        </GridItem>
        <GridItem>
          <Document
            file={fileSource}
            onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} scale={scale} />
          </Document>
        </GridItem>
      </Grid>
    </>
  );
};

export default PDFReader;
