import React, { useState, useEffect } from "react";
import { makeStyles, styled } from '@material-ui/core/styles';

import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";

import Button from '@material-ui/core/Button';
import ColoredCircle from 'components/ColoredCircle/ColoredCircle'
import GridItem from "components/Grid/GridItem";
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress'
import Switch from '@material-ui/core/Switch'
import RWSFooter from "components/Footer/RWSFooter";

//Helpers
import { handleHttpResponseError } from "utils/HandleError"

//Services
import GateService from "services/GateService"

import { useAuth } from 'utils/AuthUser'
import { useSignalR } from "utils/SignalR";

import "react-circular-progressbar/dist/styles.css";
import "react-datepicker/dist/react-datepicker.css";

import RWSAdminNavbarLink from "components/Navbars/RWSAdminNavbarLinks.js";

import { mockGetEGate } from "mock/egate"

const AntSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    color: '#999AA0',
    '&.Mui-checked': {
      color: '#297373',
      '& + .MuiSwitch-track': {
        opacity: 0.5,
        backgroundColor: theme.palette.mode === 'dark' ? '#297373' : '#297373',
      },
    },
  },
  '& .MuiSwitch-track': {
    opacity: 0.5,
    backgroundColor:
      theme.palette.mode === 'dark' ? '#E94F37' : '#E94F37',
  },
}));

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  root: {
    width: '100%',
    overflowX: 'auto'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  table: {
    minWidth: 700
  },
}));

const tableHeader = [
  "Name",
  "Status",
  "Entrance Light",
  "Exit Light",
  "Red Light",
  "Yellow Light",
  "Green Light",
  "Blue Light",
]

const ExpandableTableRow = ({ children, expandComponent, ...otherProps }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <TableRow {...otherProps}>
        <TableCell padding="checkbox">
          <Button variant='outlined' color="primary" onClick={() => setIsExpanded(!isExpanded)}>Controls</Button>
        </TableCell>
        {children}
      </TableRow>
      {isExpanded && expandComponent}
    </>
  );
};

const ExpandTableCell = ({ gate, onClickForDoors }) => {

  return (
    <>
      {gate !== null ?
        (<TableRow key={gate.name}>
          <TableCell align='left' size='small' colSpan={tableHeader.length + 1}>
            <Grid container alignItems="center" alignContent="center">
              <GridItem xs={12}>
                <Typography variant="h6"><u>Gate Controls</u></Typography>
              </GridItem>
              <GridItem>
                <Typography align="center" variant="subtitle1">{"Door A"}</Typography>
                <Grid container direction="column" justify='center' alignItems='center'>
                  <GridItem>
                    {gate.DoorA ? <ColoredCircle color="#297373" /> : <ColoredCircle color="#999AA0" />}
                  </GridItem>
                  <GridItem>
                    <Button id={"doorA"} value={gate.Code} variant="contained" onClick={onClickForDoors}>{gate.DoorA ? "On" : "Off"}</Button>
                  </GridItem>
                </Grid>
              </GridItem>
              <GridItem>
                <Typography align="center" variant="subtitle1">{"Door B"}</Typography>
                <Grid container direction="column" justify='center' alignItems='center'>
                  <GridItem>
                    {gate.DoorB ? <ColoredCircle color="#297373" /> : <ColoredCircle color="#999AA0" />}
                  </GridItem>
                  <GridItem>
                    <Button id={"doorB"} value={gate.Code} variant="contained" onClick={onClickForDoors}>{gate.DoorB ? "On" : "Off"}</Button>
                  </GridItem>
                </Grid>
              </GridItem>
            </Grid>
          </TableCell>
        </TableRow>) :
        (<TableRow key="null">
          <TableCell align='left' size='small' colSpan={tableHeader.length + 1}>
            <b>No devices</b>
          </TableCell>
        </TableRow>)}
    </>
  )
}

export default function Dashboard() {
  const classes = useStyles();
  const auth = useAuth();
  const signalR = useSignalR();

  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [gateList, setGateList] = useState([]);

  // Component will did mount 
  useEffect(() => {

    async function getGates() {
      try {
        setIsLoading(true)
        var data = await GateService.getGateList()
        setGateList(data)
        setIsLoading(false)

      } catch (error) {
        handleHttpResponseError(auth, error)
      }
    }

    getGates()
  }, [signalR, auth])

  const handleOnClickForDoors = async (event) => {
    var gate = gateList.find(x => x.Code === event.currentTarget.value)

    try {
      if(event.currentTarget.id === "doorA") {
        await GateService.updateGateDoor(gate.Code, !gate.DoorA, true, false)
        alert("Request Successfully sent! The status will update once the door has responded.")
      } else if(event.currentTarget.id === "doorB") {
        await GateService.updateGateDoor(gate.Code, !gate.DoorA, false, true)
        alert("Request Successfully sent! The status will update once the door has responded.")
      }

    } catch (error) {
      handleHttpResponseError(auth, error)
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <RWSAdminNavbarLink />

      <Grid container direction="row" justify="space-between" alignItems="center">
        <GridItem>
          <Typography
            style={{ margin: '10px' }}
            variant='h6'>eGate management</Typography>
        </GridItem>
      </Grid>

      <div>
        <Paper elevation={2} style={{ marginLeft: '18px', marginRight: '18px' }}>
          <TableContainer>
            <Table className={classes.table} stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox" />
                  {tableHeader.map((headerTitle, index) => (
                    <TableCell
                      key={index}
                      align='center'
                    >{headerTitle}</TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell padding='none' size='small' colSpan={tableHeader.length + 1}>
                    <LinearProgress hidden={!isLoading} />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {gateList.count !== 0 ?
                  (gateList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((gate, index) => (
                    <ExpandableTableRow
                      key={gate.Name}
                      expandComponent={<ExpandTableCell gate={gate} onClickForDoors={handleOnClickForDoors} />} >
                      <TableCell align='left'>{gate.Name}</TableCell>
                      <TableCell align='center'>{gate.IsOnline ? <ColoredCircle color="#297373" /> : <ColoredCircle color="#E94F37" />}</TableCell>
                      <TableCell align='center'>{gate.EntranceLight ? <ColoredCircle color="#297373" /> : <ColoredCircle color="#999AA0" />}</TableCell>
                      <TableCell align='center'>{gate.ExitLight ? <ColoredCircle color="#297373" /> : <ColoredCircle color="#999AA0" />}</TableCell>
                      <TableCell align='center'>{gate.RedLight ? <ColoredCircle color="#E94F37" /> : <ColoredCircle color="#999AA0" />}</TableCell>
                      <TableCell align='center'>{gate.YellowLight ? <ColoredCircle color="#E5E511" /> : <ColoredCircle color="#999AA0" />}</TableCell>
                      <TableCell align='center'>{gate.GreenLight ? <ColoredCircle color="#297373" /> : <ColoredCircle color="#999AA0" />}</TableCell>
                      <TableCell align='center'>{gate.BlueLight ? <ColoredCircle color="#1D80CD" /> : <ColoredCircle color="#999AA0" />}</TableCell>
                    </ExpandableTableRow>
                  )))
                  : null
                }
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 15, 20]}
              component="div"
              count={gateList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Paper>

      </div>

      <RWSFooter />
    </>
  );
}
