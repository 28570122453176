//Dashboard
import Dashboard from "views/Dashboard/Dashboard";

//User Management
import UserManagement from "views/User/UserManagement"

var privateRoutes = [
  {
    path: "/admin/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/admin/usermanagement",
    name: "User management",
    component: UserManagement,
  },
];

export default privateRoutes;