import React, { useState, useContext, createContext } from "react";
import { useAuth } from 'utils/AuthUser'

// import { getNotificationListByAccessControl } from "utils/stringHelper";

//SignalR setup jquery in react.
//To know more about this go to here. Currently our backend signalR is not using Dot.net core SignalR implementation.
//https://docs.microsoft.com/en-us/aspnet/signalr/overview/guide-to-the-api/hubs-api-guide-javascript-client#logging
//more implemetation examples
//https://stackoverflow.com/questions/46190574/how-to-import-signalr-in-react-component
import $ from 'jquery'
window.jQuery = $;
require('signalr');

const signalRContext = createContext();
var connection = null;
var isConnecting = false;
var isConnected = false;

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideSignalR({ children }) {
    const SignalR = useProvideSignalR();
    return <signalRContext.Provider value={SignalR}>{children}</signalRContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useSignalR = () => {
    return useContext(signalRContext);
};

// Provider hook that creates auth object and handles state
function useProvideSignalR() {
    // const [connection, setConnection] = useState(null);
    const auth = useAuth();
    const [message, setMessage] = useState(null);
    const [kioskStatusMessage, setKioskStatusMessage] = useState(null);
    // const [alertTypeList] = useState(getNotificationListByAccessControl(auth.accessControlList))

    if (connection == null) {
        // var connection = $.hubConnection('http://spcp.assimilated.com.sg/signalr/hubs');
        // var connection = $.hubConnection('http://172.16.50.59/signalr/hubs');
        // var hubConnection = $.hubConnection('http://172.16.50.59:85/satellite/signalr/hubs');
        var hubConnection = $.hubConnection(process.env.REACT_APP_SIGNALR_HUB);
        
        var proxy = hubConnection.createHubProxy('adminhub');

        proxy.logging = true;

        proxy.on('gateMessageSend', (messagesItem) => {
            console.log(messagesItem)
            // if(messagesItem.Type != null && alertTypeList.some(alert => alert.Id === messagesItem.Type)) {
                setMessage(messagesItem)
            // }
        })

        proxy.on('gateStatusChanged', (messagesItem) => {
            console.log(messagesItem)
            setKioskStatusMessage(messagesItem)
        })

        connection = hubConnection;
    }

    // Wrap any Firebase methods we want to use making sure ...
    // ... to save the user to state.
    const connect = () => {

        if (connection && !isConnecting && !isConnected) {
            // atempt connection, and handle errors
            isConnecting = true
            connection.start()
                .done(() => {
                    isConnecting = false
                    isConnected = true
                    console.log('Now connected, connection ID=' + connection.id)
                    console.log("Connected, transport = " + connection.transport.name);

                })
                .fail(() => { console.log('Could not connect'); });

            connection.disconnected(() => {
                isConnecting = false
                isConnected = false
                console.log('disconnected');
            });
        }
    };

    const disconnect = () => {
        isConnected = false
        connection.stop()
    };

    const emptyMessage = () => {
        setMessage(null)
    }

    const emptyKioskStatusMessage = () => {
        setKioskStatusMessage(null)
    }

    // Return the user object and auth methods
    return {
        message,
        kioskStatusMessage,
        connect,
        disconnect,
        emptyMessage,
        emptyKioskStatusMessage
    };
}