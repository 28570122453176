import React from 'react';
// nodejs library to set properties for components
import PropTypes from "prop-types";

export default function ColoredCircle(props) {

    const { color, width, height } = props;

    var circleStyle = {
        padding: 0,
        margin: 0,
        display: "inline-block",
        // position:'absolute',
        backgroundColor: color,
        borderRadius: "50%",
        width: width ? width : 18,
        height: height ? height : 18,
        marginLeft: 5
    };

    return color ? (
        <>
            <span style={circleStyle} />
        </>
    ) : null;
};

ColoredCircle.propTypes = {

    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};