import React from 'react'
import { Route, Switch, Redirect } from "react-router-dom";
import { useAuth } from 'utils/AuthUser'

import privateRoutes from "privateRoutes"

//RWS Home
import Home from "views/Home/Home";
//Experimentatl
import Experimental from "views/Experiment/Experimental";

export default function Routers() {
  const auth = useAuth();

  // A wrapper for <Route> that redirects to the login
  // screen if you're not yet authenticated.
  function PrivateRoute({ auth: Auth, component: Component, ...rest }) {

    const { path } = rest

    return (
      <Route
        {...rest}
        render={(props) => Auth && Auth.user && Auth.user.token && (Auth.route.some(menu => menu.Path.toLowerCase() === path.toLowerCase()) || path === "/admin/dashboard")
          ? <Component {...props} />
          : <Redirect to={{ pathname: '/home' }} />}
      />
    )
  }

  return (
    <>
      <Switch>
        <Route exact path="/home" component={Home} />
        {/* <Route exact path="/test" component={Experimental} /> */}
        {privateRoutes.map((route, index) => {
          return (<PrivateRoute key={index} exact path={route.path} auth={auth} component={route.component} />)
        })}
        <Redirect from="/" to="/home" />
      </Switch>
    </>
  )
}