function handleHttpResponseError(auth, error) {
    if (error && error.response && error.response.status >= 400 && error.response.status < 500) {

        if(error.response.status === 400) {
          alert("Bad Request!! Check the integration code!!")
        } else {
          auth.signout()
        }        
      } else {
        if (process.env.REACT_APP_MODE !== 'prod') {
          alert(error)
        } else {
          alert('Oposs!! Something when wrong')
        }
      }
}

export {
    handleHttpResponseError
}