import { getUrl } from "utils/httpClient"
import axios from 'axios'

const GetGateListUrl = "/gate/getgates"
const UpdateGateDoorUrl = "/gate/updategatedoor"

async function getGateList(locationId) {

    const instance = axios.create({
        //More setting can be found here https://github.com/axios/axios
        baseURL: getUrl().BASEURL + getUrl().URLPREFIX,
        // timeout: 3000,
        headers: {
            "Content-type": "application/json",
            "Authorization" : `Bearer ${window.localStorage.getItem("user") ? JSON.parse(window.localStorage.getItem("user")).token : ""}`
        }
    })

    const response = await instance.get(GetGateListUrl, {
        params: {
            locationId: locationId
        }
    })
    const {
        data
    } = response
    return data
}

async function updateGateDoor(GateCode, IsOpen, isDoorA, isDoorB) {
    const instance = axios.create({
        //More setting can be found here https://github.com/axios/axios
        baseURL: getUrl().BASEURL + getUrl().URLPREFIX,
        // timeout: 3000,
        headers: {
            "Content-type": "application/json",
            "Authorization" : `Bearer ${window.localStorage.getItem("user") ? JSON.parse(window.localStorage.getItem("user")).token : ""}`
        }
    })

    const response = await instance.post(UpdateGateDoorUrl, {
        GateCode: GateCode,
        IsOpen: IsOpen,
        DoorA: isDoorA,
        DoorB: isDoorB
    })
    const {
        data
    } = response
    return data
}

export default {
    getGateList,
    updateGateDoor
}