//more info can be found here https://www.npmjs.com/package/react-pdf

import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { ReactSketchCanvas } from 'react-sketch-canvas';
import ControlPanel from './ControlPanel';
import { useDebouncedCallback } from 'use-debounce'

import { PDFDocument } from 'pdf-lib'

import Grid from '@material-ui/core/Grid';
import GridItem from 'components/Grid/GridItem';

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js'; //manually import worker

const styles = {
    border: '0.0625rem solid #9c9c9c',
    borderRadius: '0.25rem',
};

const PDFScribble = ({ filename = "file.pdf", file, fileInBase64 }) => {
    const [scale, setScale] = useState(1.0);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [sketchPaths, setSketchPaths] = useState([]);

    const [isHeightChange, setIsHeightChange] = useState(true)

    const [height, setHeight] = useState(0)
    const [width, setWidth] = useState(0)
    const [top, setTop] = useState(0)
    const [left, setLeft] = useState(0)
    const [fileSource] = useState(file ? file : `data:application/pdf;base64,${fileInBase64}`)
    const [downloadFileSource, setDownloadFileSource] = useState(file ? file : `data:application/pdf;base64,${fileInBase64}`)

    const debounced = useDebouncedCallback(
        // function
        async function (data) {
            const canvasData = await canvasRef.current.exportImage("png")
            const oriPdfDoc = await PDFDocument.load(fileSource)
            const pdfDoc = await PDFDocument.load(downloadFileSource)
            
            const imageBase64 = canvasData.replace(/^data:image\/(png|jpg);base64,/, "");
            const pngImage = await pdfDoc.embedPng(imageBase64)
            pdfDoc.removePage(pageNumber - 1)
            const [existingPage] = await pdfDoc.copyPages(oriPdfDoc, [pageNumber - 1])
            pdfDoc.insertPage(pageNumber - 1, existingPage)

            const pages = pdfDoc.getPages()
            const editPage = pages[pageNumber - 1]

            editPage.drawImage(pngImage, {
                x: 0,
                y: 0,
                width: editPage.getWidth(),
                height: editPage.getHeight(),
            })
            console.log("save")
            const base64String = await pdfDoc.saveAsBase64();
            setDownloadFileSource(`data:application/pdf;base64,${base64String}`)

        },
        // delay in ms
        500
    );

    const canvasRef = React.createRef();
    const documentRef = React.createRef();

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    React.useLayoutEffect(() => {
        setTimeout(() => {
            if (documentRef.current != null) {
                const a = documentRef.current.getBoundingClientRect()
                if (isHeightChange) {
                    setHeight(a.height)
                    setWidth(a.width)
                    setTop(a.top)
                    //added 15 because the grid had 15 padding size
                    setLeft(a.left - 15)
                    setIsHeightChange(false)
                }
            }

        }, 100);
    })

    // async function handleOnClick() {
    //     canvasRef.current.exportImage("png")
    //         .then(async function (data) {

    //             const imageBase64 = data.replace(/^data:image\/(png|jpg);base64,/, "");

    //             const pdfDoc = await PDFDocument.load(fileSource)
    //             const pngImage = await pdfDoc.embedPng(imageBase64)
    //             const pages = pdfDoc.getPages()
    //             const editPage = pages[pageNumber - 1]

    //             editPage.drawImage(pngImage, {
    //                 x: 0,
    //                 y: 0,
    //                 width: editPage.getWidth(),
    //                 height: editPage.getHeight(),
    //             })

    //             const base64String = await pdfDoc.saveAsBase64();

    //             canvasRef.current.clearCanvas()
    //             setFileSource(`data:application/pdf;base64,${base64String}`)

    //         })
    //         .catch(e => {
    //             console.log(e);
    //         });
    // }

    //write directly when next page
    // async function onPageChange(number) {

    //     if (!isEmptySketch) {

    //         canvasRef.current.exportImage("png")
    //             .then(async function (data) {

    //                 const imageBase64 = data.replace(/^data:image\/(png|jpg);base64,/, "");

    //                 const pdfDoc = await PDFDocument.load(fileSource)
    //                 const pngImage = await pdfDoc.embedPng(imageBase64)
    //                 const pages = pdfDoc.getPages()
    //                 const editPage = pages[pageNumber - 1]

    //                 editPage.drawImage(pngImage, {
    //                     x: 0,
    //                     y: 0,
    //                     width: editPage.getWidth(),
    //                     height: editPage.getHeight(),
    //                 })

    //                 const base64String = await pdfDoc.saveAsBase64();
    //                 setFileSource(`data:application/pdf;base64,${base64String}`)

    //             })
    //             .catch(e => {
    //                 console.log(e);
    //             });

    //         canvasRef.current.resetCanvas()
    //     }
    //     setIsEmptySketch(true)
    // }

    async function onPageChange(newPageNumber) {
        const _canvas = canvasRef.current;
        const currentPage = newPageNumber - 1
        const previuosPage = pageNumber - 1

        //Process canvas
        //1. save the paths
        //2. save the image into the PDF file
        const previousCanvasPaths = await _canvas.exportPaths()
        // var previousScreenCanvasImageData = null
        // if (previousCanvasPaths.length > 0) {
        //     previousScreenCanvasImageData = await _canvas.exportImage("png")
        // }
        _canvas.resetCanvas()

        var currentCanvasPaths = sketchPaths[currentPage]
        if (currentCanvasPaths) {
            _canvas.loadPaths(currentCanvasPaths)
        }

        sketchPaths.splice(previuosPage, 1, previousCanvasPaths)
        setSketchPaths(sketchPaths)
    }

    function onPageZoom(scaleNumber) {
        setIsHeightChange(true);
        setScale(scaleNumber);
    }

    return (
        <>
            <Grid container direction='column' alignItems='center' justify='center' alignContent='center'>
                <GridItem xs={12}>
                    <ControlPanel
                        scale={scale}
                        setScale={setScale}
                        numPages={numPages}
                        pageNumber={pageNumber}
                        withEditControls
                        setPageNumber={setPageNumber}
                        file={downloadFileSource}
                        filename={filename}
                        onZoom={onPageZoom}
                        onNextPage={onPageChange}
                        onClear={() => canvasRef.current.clearCanvas()}
                        onRedo={() => canvasRef.current.redo()}
                        onUndo={() => canvasRef.current.undo()}
                    />
                </GridItem>
                <GridItem xs={12} style={{ zIndex: -1, borderColor: "black", borderStyle: "solid" }}>
                    <Document
                        file={fileSource}
                        onLoadSuccess={onDocumentLoadSuccess}>
                        <Page
                            inputRef={documentRef}
                            renderMode="svg"
                            pageNumber={pageNumber} scale={scale} />
                    </Document>

                </GridItem>
                <GridItem xs={12} style={{ zIndex: 1, position: "absolute", top: top, left: left }}>
                    <ReactSketchCanvas
                        style={styles}
                        ref={canvasRef}
                        height={height}
                        width={width}
                        canvasColor="#ffffff"
                        background="transparent"
                        strokeWidth={3}
                        strokeColor="black"
                        onUpdate={(data) => debounced(data)}
                        withTimestamp
                    />
                </GridItem>
            </Grid>
        </>
    );
};

export default PDFScribble;
