const mockGetEGate = [{
        name: "eGate 1",
        status: true,
        devices: [{
                name: 'Door A',
                status: true,
            },
            {
                name: 'Door B',
                status: false,
            },
            {
                name: 'Arrow LED Light',
                status: false,
            },
            {
                name: 'Cross LED Light',
                status: false,
            },
            {
                name: 'Green LED strips',
                status: false,
            },
            {
                name: 'Red LED strips',
                status: false,
            },
            {
                name: 'Blue LED strips',
                status: false,
            },
            {
                name: 'Yellow LED strips',
                status: false,
            }
        ]
    },
    {
        name: "eGate 2",
        status: true,
        devices: [{
                name: 'Door A',
                status: true,
            },
            {
                name: 'Door B',
                status: false,
            },
            {
                name: 'Arrow LED Light',
                status: false,
            },
            {
                name: 'Cross LED Light',
                status: false,
            },
            {
                name: 'Green LED strips',
                status: false,
            },
            {
                name: 'Red LED strips',
                status: false,
            },
            {
                name: 'Blue LED strips',
                status: false,
            },
            {
                name: 'Yellow LED strips',
                status: false,
            }
        ]
    }
]

export {
    mockGetEGate,
}