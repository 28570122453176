import React, { useState } from "react";
import { useHistory } from "react-router-dom"

// core components
import { Nav, Navbar, NavDropdown, Form } from "react-bootstrap"

//RWS icons
import rwslogo from "assets/img/rws-globe.png";
import { Button, Typography } from "@material-ui/core";
import { useAuth } from 'utils/AuthUser'
import { useSignalR } from 'utils/SignalR'
import { convertDateTimeToString } from 'utils/dateHelper'
import Alert from '@material-ui/lab/Alert';
import { Grid } from "@material-ui/core";
import GridItem from "components/Grid/GridItem";

export default function RWSAdminNavbarLinks() {
  const auth = useAuth();
  const signalR = useSignalR();
  const history = useHistory();
  const [menuList] = useState([
    {
      title: "System",
      dropdownItem: [{
        title: "User Management",
        uri: "/admin/usermanagement"
      }]
    }
  ])

  const handleSelect = (eventKey) => { history.push(eventKey) }

  const handleOnClick = (event) => { history.push(event.currentTarget.value) }

  const handleOnLogoutClick = (event) => {
    auth.signout()
    // signalR.disconnect()
    history.push(event.currentTarget.value)
  }

  signalR.connect()

  var gateStatusMessage = "";

  if (signalR.kioskStatusMessage != null) {
    gateStatusMessage = `[${convertDateTimeToString(new Date())}] ${signalR.kioskStatusMessage.Name} [${signalR.kioskStatusMessage.Code}] : Status updated.`

    // if (signalR.kioskStatusMessage.IsOnline === false) {
    //   gateStatusMessage = `[${convertDateTimeToString(new Date())}] ${signalR.kioskStatusMessage.Name} [${signalR.kioskStatusMessage.Code}] : is offline.`
    // }
    // else if (signalR.kioskStatusMessage.IsOnline === true) {
    //   gateStatusMessage = `[${convertDateTimeToString(new Date())}] ${signalR.kioskStatusMessage.Name} [${signalR.kioskStatusMessage.Code}] : is online.`
    // } 
  }

  return (
    <>
      <Navbar bg="dark" variant="dark" expand="md" >
        <Navbar.Brand href="/admin/dashboard">
          <img alt={"logo"} src={rwslogo} style={{ height: "4vh" }} />
          {` eGate Management System `}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Button style={{ color: '#FFF9FF80', textTransform: 'none', fontSize: '16px', marginTop: '1px' }} value={"/admin/dashboard"} onClick={handleOnClick}>Home</Button>
          <Nav className="mr-auto" onSelect={handleSelect}>
            {menuList.map(item => (
              <NavDropdown key={item.title} title={item.title} id="nav-dropdown">
                {item.dropdownItem.map(item => (
                  <NavDropdown.Item key={item.title} eventKey={item.uri}>{item.title}</NavDropdown.Item>
                ))}
              </NavDropdown>
            ))}
          </Nav>
          <Grid container direction='column' alignContent='flex-end' alignItems='flex-end'>
            <GridItem align="end">
              <Typography variant='body2' color="primary">{auth.user.name}</Typography>
            </GridItem>
            <GridItem align="end">
              <Typography variant='caption' color="primary">{auth.user.role}</Typography>
            </GridItem>
          </Grid>
          <Form inline>
            <Button style={{ color: '#FFF9FF80', textTransform: 'none', fontSize: '16px', marginTop: '1px' }} value={"/home"} onClick={handleOnLogoutClick}>Logout</Button>
          </Form>
        </Navbar.Collapse>
      </Navbar>
      <Alert onClose={() => signalR.emptyMessage()} hidden={signalR.message === null ? true : false} severity={signalR.message && signalR.message.Type === 1 ? "info" : "error"}>{signalR.message != null ? "[" + convertDateTimeToString(signalR.message.SentTime) + "] " + signalR.message.GateId + " : " + signalR.message.Description : ""}</Alert>
      <Alert onClose={() => signalR.emptyKioskStatusMessage()} hidden={signalR.kioskStatusMessage === null ? true : false} severity='info'>{gateStatusMessage}</Alert>
      <div style={{ paddingBottom: "16px" }}></div>
    </>
  );
}
